import React, { useState, Fragment } from 'react';
import { Container } from 'reactstrap';
import Carousel from "react-elastic-carousel";
import {
    IoIosArrowDropleft,
    IoIosArrowDropright,
} from "react-icons/io";
// @ts-ignore
import map from "lodash/map";
import { userProfileIcon } from './assets';
import '../assets/css/reviews.css';
// Customizable Area Start
// Customizable Area End

function Item(props: any) {
    // Customizable Area Start
    return props.isLastItem ? (
        <div className="yt-review-item d-flex flex-column" style={{ marginBottom: 'unset' }}>
       
            <div
                className="text-content-block"
                style={{ borderBottom: 'unset', paddingBottom: 'unset' }}
            >
                <div className="item-name">{props.name}</div>
                <div className="item-comment">{props.comment}</div>
            </div>
        </div>
    ) : (
        <div className="yt-review-item d-flex flex-column justify-content-center align-items-center">
            <img
                src={
                    props.imageUrl
                        ? props.imageUrl
                        : userProfileIcon
                    // require('./images/profile-image.png')
                }
                alt="Profile Image"
                className="profile-image"
                width="62"
                height="62"
            />
            <div className="text-content-block">
                <div className="item-name">{props.name}</div>
                <div className="item-comment">{props.comment}</div>
            </div>
        </div>
    );
    // Customizable Area End
}

function AboutUsReviews(props: any) {
    // Customizable Area Start
    const [limit, setLimit] = useState(5);
    const itemsCollections = [
        {
            name: "Rameshwar Maheta",
            comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            name: "Rameshwar Maheta",
            comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            name: "Rameshwar Maheta",
            comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            name: "Rameshwar Maheta",
            comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            name: "Rameshwar Maheta",
            comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            name: "Rameshwar Maheta",
            comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            name: "Rameshwar Maheta",
            comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            name: "Rameshwar Maheta",
            comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            name: "Rameshwar Maheta",
            comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            name: "Rameshwar Maheta",
            comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        // Customizable Area End
    ];
    let carousel = React.createRef();
    let Breakpoints = [
        { width: 200, itemsToShow: 1, itemsToScroll: 1 },
        { width: 320, itemsToShow: 2, itemsToScroll: 1 },
        { width: 500, itemsToShow: 3, itemsToScroll: 1 },
        { width: 769, itemsToShow: 5, itemsToScroll: 2 },
        { width: 1000, itemsToShow: 5, itemsToScroll: 2 },
        { width: 1300, itemsToShow: 7, itemsToScroll: 3 },
    ];
    return (
        // Customizable Area End
        <section className="yt-review-main-wrapper">
            <Container>
                <div className="yt-inner-wrap">
                    <h2 className="yt-section-title mt-0 mb-0">
                        What our Customer say
                    </h2>
                    <div
                        className="yt-inner-content bg-white border-radius-10"
                        style={{ marginBottom: '30px' }}
                    >
                        <Fragment>
                            <section className="collections">
                                <h2></h2>
                                <div className="carousal">
                                    <button
                                        style={{
                                            border: "none",
                                            outline: "none",
                                            background: "transparent",
                                            color: "#324688",
                                        }}
                                        className="carousel__back-button"
                                    >
                                        <IoIosArrowDropleft
                                            className="slider-left img-fluid"
                                            /*@ts-ignore */
                                            onClick={() => carousel.current.slidePrev()}
                                        />
                                    </button>
                                    <button
                                        style={{
                                            border: "none",
                                            outline: "none",
                                            background: "transparent",
                                            color: "#324688",
                                        }}
                                        className="carousel__next-button"
                                    >

                                        <IoIosArrowDropright
                                            className="slider-right img-fluid"
                                            /* @ts-ignore */
                                            onClick={() => carousel.current.slideNext()}
                                        />
                                    </button>

                                    <Carousel
                                        isRTL={false}
                                        pagination={false}
                                        showArrows={false}
                                        //@ts-ignore
                                        ref={carousel}
                                        breakPoints={Breakpoints}
                                    >
                                        {map(props?.feedbackData, (item: any, index: any) => {
                                            return (
                                                <Item
                                                    key={index}
                                                    name={item.customer_name}
                                                    comment={item.description}
                                                    isLastItem={(index + 1) === itemsCollections.length}
                                                    imageUrl={item.profile_image}
                                                />
                                            )
                                        })}
                                    </Carousel>

                                </div>
                            </section>
                        </Fragment>
                    </div>
                </div>
            </Container>
        </section>
        // Customizable Area End
    )
}

export default AboutUsReviews;
// Customizable Area Start
// Customizable Area End
